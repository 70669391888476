import { where } from "firebase/firestore"
import { useOfferingId } from "hooks/firestore/advanced/useOfferingId"
import { useStorefrontId } from "hooks/firestore/advanced/useStorefrontId"
import { getQuery, useQuery } from "hooks/firestore/core/useQuery"
import { getProductGroupsRef } from "hooks/firestore/getRefs"
import { useMemo } from "react"

export function useSpecialOffers(hideInvisible?: boolean) {
  const offeringId = useOfferingId()
  const storefrontId = useStorefrontId()
  const { documents } = useQuery(
    getProductGroupsRef(offeringId, storefrontId),
    where("specialOffer.active", "==", true)
  )

  const sortedDocuments = useMemo(() => {
    if (!documents) return []
    return [...documents].sort((a, b) => {
      const posA = a.specialOffer?.position ?? 9999999
      const posB = b.specialOffer?.position ?? 9999999
      return posA - posB
    })
  }, [documents])

  const filteredSpecialOffers = useMemo(
    () => (hideInvisible ? sortedDocuments?.filter(doc => doc.visible && doc.categoryTreeVisible) : sortedDocuments),
    [sortedDocuments, !!hideInvisible]
  )
  return filteredSpecialOffers
}

export async function getSpecialOffers(offeringId: string, storefrontId: string, hideInvisible?: boolean) {
  const documents = await getQuery(
    getProductGroupsRef(offeringId, storefrontId),
    where("specialOffer.active", "==", true)
  )

  const sortedDocuments = documents
    ? [...documents].sort((a, b) => {
        const posA = a.specialOffer?.position ?? 9999999
        const posB = b.specialOffer?.position ?? 9999999
        return posA - posB
      })
    : []

  const filteredSpecialOffers = hideInvisible
    ? sortedDocuments?.filter(doc => doc.visible && doc.categoryTreeVisible)
    : sortedDocuments
  return filteredSpecialOffers
}
